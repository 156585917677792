import {LitElement, html, customElement, css, property, unsafeCSS} from 'lit-element';
import {OwnersStatisticsVO, PackageSummaryVO, StatisticsVO, UserVO} from "../types";
import iconChecked from 'url:../../resources/check-circle.svg';
import userPlus from 'url:../../resources/account-plus.svg';
import commentIco from 'url:../../resources/comment.svg';
import {pubSubService} from '../services/pupsub-services';
import {statisticsService} from '../services/statistics-services';
import {TOPIC_NAME} from '../services/user-services';

@customElement("home-logged")
class HomeLogged extends LitElement {

	static get styles() {
		return css`
			.container {
				width: 80%;
				margin: 20px auto;
			}
			header {
				background-color: var(--primary-dark);
				padding: 20px;
				color: white;
				text-align: center;
			}
			.dashboard {
				display: grid;
				grid-template-columns: 1fr 2fr;
				gap: 20px;
			}
			.card {
				background: white;
				padding: 20px;
				border-radius: 8px;
				box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
			}
			.card h2 {
				margin-top: 0;
			}
			.card p {
				margin-bottom: 0;
				font-size: 14px;
			}
			.statistics {
				display: flex;
				justify-content: space-around;
				margin: 20px 0;
			}
			.statistics .stat {
				background: var(--primary-dark);
				color: white;
				padding: 15px;
				border-radius: 8px;
				text-align: center;
				flex: 1;
				margin: 5px;
			}
			.table {
				width: 100%;
				border-collapse: collapse;
			}
			.table th, .table td {
				padding: 12px;
				text-align: left;
				border-bottom: 1px solid #ddd;
			}
			.table th {
				background-color: var(--primary-dark);
				color: white;
			}
			.btn {
				background-color: var(--primary-dark);
				color: white;
				padding: 10px 15px;
				text-decoration: none;
				border-radius: 5px;
				display: inline-block;
				margin: 10px 0;
			}
			.btn:hover {
				background-color: var(--primary-color);
			}
			.ico {
				width: 1.2em;
				height: 1.2em;
				content: ' ';
				display: inline-block;
				margin-bottom: -0.2em;
			}
			.ico-check-circle {
				background: url(${unsafeCSS(iconChecked)});
			}
			.ico-user-plus {
				background: url(${unsafeCSS(userPlus)});
			}
			.ico-comment {
				background: url(${unsafeCSS(commentIco)});
			}
		`;
	}

	@property({
		type: Object,
		attribute: false
	})
	userData: UserVO | undefined;

	@property()
	latestReleases?: PackageSummaryVO[];

	@property()
	statistics?: StatisticsVO;

	@property()
	myStatistics?: OwnersStatisticsVO;

	render() {
		return html`
            <header>
                <h1>Welcome to Your Dashboard, ${this.userData?.name}</h1>
            </header>

            <div class="container">
                <div class="statistics">
                    <div class="stat">
                        <h3>Total Artifacts</h3>
                        <p>${this.statistics?.totalArtifacts || 0}</p>
                    </div>
                    <div class="stat">
                        <h3>Downloads This Month</h3>
                        <p>${this.statistics?.downloadsLast30Days || 0}</p>
                    </div>
                    <div class="stat">
                        <h3>New Users</h3>
                        <p>${this.statistics?.newUsers || 0}</p>
                    </div>
                </div>

                <div class="dashboard">
                    <div class="card">
                        <h2>Latest Published Artifacts</h2>
                        <table class="table">
                            <tr>
                                <th>Artifact Name</th>
                                <th>Author</th>
                                <th>Date</th>
                                <th>Actions</th>
                            </tr>
							${
								this.latestReleases?.map((release) =>
								html`
									<tr>
										<td title="${release.groupName}/${release.name}/${release.version}">${release.name}/${release.version}</td>
										<td>${release.ownerNickname}</td>
										<td>${new Date(release.releaseDate).toLocaleDateString()}</td>
										<td><a href="#" class="btn">View</a></td>
									</tr>`)
							}
                        </table>
                    </div>

                    <div class="card">
                        <h2>Recent Activities</h2>
                        <p><i class="ico ico-check-circle"></i> Your packages where downloaded ${this.myStatistics?.downloadsLast7Days} this week.</p>
                        <p><i class="ico ico-user-plus"></i> Artifact ${this.myStatistics?.topPackageDownload7Days.packageName} was downloaded ${this.myStatistics?.topPackageDownload7Days.count} this week.</p>
                        <p><i class="ico ico-comment"></i> Artifact ${this.myStatistics?.packageReviewsToday.count} received ${this.myStatistics?.packageReviewsToday.count} new reviews.</p>
                    </div>
                </div>
            </div>
		`;
	}

	constructor() {
		super();
	}

	connectedCallback() {
		super.connectedCallback();
		const assignUser = ((_topic: string, user: any) => {
			this.userData = user;
			statisticsService.ownerStatistics(this.userData?.id || 0).then(((sta:OwnersStatisticsVO) => {
				this.myStatistics = sta;
			}).bind(this));
		}).bind(this);
		pubSubService.subscribe(TOPIC_NAME, assignUser);
		statisticsService.latestReleases('PACKAGE', 0, 3).then(((sta:PackageSummaryVO[]) => {
			this.latestReleases = sta;
		}).bind(this));
		statisticsService.statistics().then(((sta:StatisticsVO) => {
			this.statistics = sta;
		}).bind(this));
	}
}

declare global {
	interface HTMLElementTagNameMap {
		'home-logged': HomeLogged;
	}
}
